var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            visible: _vm.visible,
            closable: true,
            "mask-closable": false,
            title: "编辑字段组名称",
            "ok-text": "确认",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "字段组名称" } },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.form.fieldGroupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fieldGroupName", $$v)
                      },
                      expression: "form.fieldGroupName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }